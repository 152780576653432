import request from '../utils/axios';
// 测试
const textApi = 'http://testcrmapi.xs9999.com.hk';
// 线上
// const originApi = window.location.origin +'/api/proxy';
const originApi = 'https://www.rlcvipltd.net/api/proxy';
//banner图配置
export function swiperBanner(data){
  return request({
    url: originApi + '/v4/PA159',
    method: 'POST',
    data
  })
}
//图片验证码
export function verifyImgCode(data){
  return request({
    url: originApi + '/v4/U001',
    method: 'POST',
    data
  })
}
//快捷注册
export function quickRegister(data){
  let clientid = localStorage.getItem('clientid')
  if(!clientid) {
    clientid =
      (Math.random() * 10000000).toString(16).substr(0, 4) +
      '_' +
      new Date().getTime() +
      '_' +
      Math.random().toString().substr(2, 5);
    localStorage.setItem('clientid', clientid)
  }
  data.ClientId = clientid;
  data.IsMt5 = true;
  return request({
    url: originApi + '/v4/U101',
    method: 'POST',
    data,
    headers: {
      platform: 2
    }
  })
}
// 获取live800
export function getLive800(params) {
  return request({
    url: originApi + '/v4/ACV100',
    method: 'get',
    params
  })
}
// 获取App渠道链接
export function getChannelInfo(code) {
  return request({
    url:'bpis/common/getChannelInfo',
    method: 'get',
    headers : {
      'appcode': code
    }
  })
}

// 根据key值获取配置信息
export function getConfigurationValue(data) {
  return request({
    url:'bpis/common/getJfConfigurationValue',
    method: 'POST',
    data
  })
}