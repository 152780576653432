<template>
  <div id="app">
    <layout-vue v-if="control" @getKey="getKey"></layout-vue>
    <router-view @controlShow="controlShow" @controlBottom="controlBottom" />
    <contactusVue v-if="showBottom"></contactusVue>
  </div>
</template>

<script>
import LayoutVue from "./components/Layout.vue";
import ContactusVue from "./components/Contactus.vue";
import { getLive800 } from "../api/info";
export default {
  name: "App",
  components: {
    LayoutVue,
    ContactusVue,
    live800Url: "",
  },
  data() {
    return {
      menuKey: null,
      control: true,
      showBottom: true,
    };
  },
  created() {
    getLive800({
      key: "PmH5LiveUrl",
    }).then((res) => {
      this.live800Url = res.data;
    });
  },
  mounted() {
    console.log('render-event, 111')
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    getKey(key) {
      this.menuKey = key;
    },
    closer() {
      this.menuKey = !this.menuKey;
    },
    controlShow(key) {
      this.control = key;
    },
    controlBottom(key) {
      this.showBottom = key;
    },
  },
};
</script>

<style lang="less">
html,
body,
#app {
  width: 100%;
  margin: 0 auto !important;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
ul {
  padding-left: 0;
  margin: 0 auto;
}

ul li {
  padding-left: 0px;
  list-style: none;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.el-message {
  min-width: 7.5rem;
  .el-message__icon {
    font-size: 20px;
  }
}
</style>